# 매장 정보 수정11
<template>
  <a-modal
    width="1200px"
    :title="
      levelCheck === '1'
        ? $t('components.modal.storeInfo')
        : $t('components.modal.updateStoreInfo')
    "
    :visible="visible"
    :closable="false"
  >
    <a-form-model
      v-if="data"
      ref="editServiceForm"
      :model="data"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <div df fdr>
        <a-space direction="vertical" flex style="padding: 10px 20px 10px 0px">
          <upload
            dir-name="icon"
            v-model="data"
            listType="picture-card"
            :selected="true"
            :disabled="levelCheck === '1' ? true : false"
          >
            <div class="image-expand">
              <div
                v-if="data.uploadedUrl[0]"
                :style="{
                  backgroundImage: `url('${data.uploadedUrl[0]}')`,
                }"
              />
              <div v-else df fdc fjc fic>
                <a-icon :type="'plus'" />
                <div>{{ $t("components.modal.upload") }}</div>
                <div>{{ $t("components.modal.uploadEx") }}</div>
              </div>
            </div>
          </upload>
        </a-space>

        <a-space direction="vertical" flex>
          <div df fdr fje>
            <small>{{ $t("components.modal.uploadCaution") }}</small>
          </div>
          <div class="card-container">
            <language-tab
              ref="languageTabForm"
              :value="data"
              :isLanguageUsed="isLanguageUsed"
              :tabs="tabs"
              :name="$t('components.modal.name')"
              :description="$t('components.modal.description')"
              :descriptionRows="1"
            >
            </language-tab>
          </div>

          <a-form-model-item prop="businessNumber" label="사업자번호">
            <a-input
              v-model="data.businessNumber"
              :max-length="10"
              @input="onBusinessNumberInput"
            />
          </a-form-model-item>

          <a-form-model-item prop="moaPosStoreId" label="모아포스 ID">
            <a-row type="flex" :gutter="8">
              <a-col :flex="1">
                <a-input
                  v-model="data.moaPosStoreId"
                  disabled
                  :style="{
                    color: 'black',
                    backgroundColor: '#f5f5f5',
                    width: '100%',
                  }"
                />
              </a-col>
              <a-col>
                <a-button type="primary" @click="clickMoaPosIdSync">
                  ID 동기화
                </a-button>
              </a-col>
            </a-row>
          </a-form-model-item>

          <a-form-model-item prop="phone" label="전화번호">
            <a-input v-model="data.phone" />
          </a-form-model-item>

          <a-form-model-item prop="groupCode" label="매장 카테고리">
            <a-select v-model="data.serviceGroupCode" style="width: 100%">
              <a-select-option
                v-for="(code, index) in groupCodeList"
                :key="code"
                :value="code"
              >
                {{ groupNameList[index] }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item
            prop="isAlways"
            :label="$t('components.modal.saleTime')"
          >
            <a-radio-group button-style="solid" v-model="data.isAlways">
              <a-radio-button @click="isAlwaysChange" :value="true">{{
                $t("components.modal.always")
              }}</a-radio-button>
              <a-radio-button @click="isAlwaysChange" :value="false">{{
                $t("components.modal.individual")
              }}</a-radio-button>
            </a-radio-group>

            <div df fdr fjb style="margin-top: 6px; margin-bottom: 6px">
              <div df fdc fic>
                {{ $t("components.modal.openTime") }}
                <a-time-picker
                  format="HH:mm"
                  :disabled="data.isAlways"
                  v-model="data.openTime"
                >
                </a-time-picker>
              </div>
              <div df fjc fic>~</div>
              <div df fdc fic>
                {{ $t("components.modal.endTime") }}
                <a-time-picker
                  format="HH:mm"
                  :disabled="data.isAlways"
                  v-model="data.closeTime"
                >
                </a-time-picker>
              </div>
            </div>
          </a-form-model-item>

          <a-form-model-item :label="$t('components.modal.delivery.minPrice')">
            <a-input-number
              style="width: 120px"
              v-model="data.minPrice"
              :formatter="
                value => `₩ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              "
              :min="0"
              :step="1000"
            />
          </a-form-model-item>

          <a-form-model-item
            :label="$t('components.modal.delivery.deliveryPrice')"
          >
            <a-radio-group button-style="solid" v-model="data.isDeliveryPrice">
              <a-radio-button :value="1">{{
                $t("components.modal.delivery.use")
              }}</a-radio-button>
              <a-radio-button :value="0">{{
                $t("components.modal.delivery.unuse")
              }}</a-radio-button>
            </a-radio-group>

            <a-input-number
              v-if="data.isDeliveryPrice"
              style="width: 120px; margin-left: 20px"
              v-model="data.deliveryPrice"
              :formatter="
                value => `₩ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              "
              :min="0"
              :step="1000"
            />
          </a-form-model-item>

          <a-form-model-item
            v-if="data.index"
            prop="status"
            :label="$t('components.modal.use')"
          >
            <a-radio-group button-style="solid" v-model="data.status">
              <a-radio-button :value="true">{{
                $t("components.modal.use")
              }}</a-radio-button>
              <a-radio-button :value="false">{{
                $t("components.modal.unuse")
              }}</a-radio-button>
            </a-radio-group>
          </a-form-model-item>
        </a-space>
      </div>
    </a-form-model>

    <div slot="footer">
      <a-button @click="closeModal">{{
        $t("components.modal.cancel")
      }}</a-button>
      <a-button
        v-if="levelCheck === '1' ? false : true"
        type="primary"
        :loading="loading"
        @click="save"
        >{{ "저장" }}</a-button
      >
    </div>
  </a-modal>
</template>

<script>
import BasCodeApi from "@/api/shared/basCode";
import DeliveryApi from "@/api/shared/delivery";
import { Component, Prop, Vue } from "vue-property-decorator";

import LanguageTab from "@/components/LanguageTab";
import Upload from "@/components/Upload";
import Tabs from "@/utils/languageTab";
import { i18n } from "@/i18n";
import moment from "moment";

@Component({
  components: { Upload, LanguageTab },
})
export default class MasterDeliveryItemEditModal extends Vue {
  levelCheck = localStorage.getItem("level");
  @Prop({ default: undefined }) data;
  @Prop({ default: false }) visible;

  labelCol = { span: 6 };
  wrapperCol = { span: 18 };

  tabs = Tabs;

  loading = false;
  basList = [];
  groupCodeList = [];
  groupNameList = [];

  async mounted() {
    await this.load();
  }

  async load() {
    try {
      const response = await BasCodeApi.ListByGroupCode("SV_DELIVERY_GROUP");
      this.basList = response.data;

      let combinedList = this.basList.map(basCode => {
        let jsonObj = JSON.parse(basCode.value2);
        return { code: basCode.code, name: jsonObj.kr };
      });

      combinedList.unshift({ code: "", name: "없음" });

      combinedList.sort((a, b) => {
        if (a.name === "없음") return -1;
        if (b.name === "없음") return 1;
        return a.name.localeCompare(b.name);
      });

      this.groupCodeList = combinedList.map(item => item.code);
      this.groupNameList = combinedList.map(item => item.name);
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.master.hotel.errorMsg");

      this.$message.error(error);
    } finally {
      console.log("Load operation completed.");
    }
  }

  closeModal() {
    this.$emit("close");
  }

  isLanguageUsed(key) {
    if (key === "Kr" || key === "En") {
      return true;
    }
  }

  isAlwaysChange() {
    this.data.openTime = "0000";
    this.data.closeTime = "0000";
  }

  onBusinessNumberInput(e) {
    this.data.businessNumber = e.target.value.replace(/[^0-9]/g, "");
  }

  async clickMoaPosIdSync() {
    try {
      // 사업자 번호 유효성 검사
      if (!this.data.businessNumber || this.data.businessNumber.length !== 10) {
        this.$message.error("사업자 번호는 10자리여야 합니다.");
        return;
      }

      const formatTime = time => {
        if (!time) return "0000";

        // time이 문자열인 경우 (예: "HH:mm" 형식)
        if (typeof time === "string") {
          return time.replace(":", "");
        }

        // time이 moment 객체인 경우
        if (time && typeof time.format === "function") {
          return time.format("HHmm");
        }

        // time이 Date 객체인 경우
        if (time instanceof Date) {
          return (
            time
              .getHours()
              .toString()
              .padStart(2, "0") +
            time
              .getMinutes()
              .toString()
              .padStart(2, "0")
          );
        }

        // 기타 경우
        console.error("Unsupported time format:", time);
        return "0000";
      };

      let openTime, closeTime;

      if (this.data.isAlways) {
        // 상시 오픈일 경우
        openTime = "0000";
        closeTime = "2359";
      } else {
        // 개별 시간일 경우
        openTime = formatTime(this.data.openTime);
        closeTime = formatTime(this.data.closeTime);
      }

      const serviceItem = {
        storeName: this.data.information.Kr.name,
        businessNumber: this.data.businessNumber,
        storeAddress: this.data.information.Kr.address || "",
        storePhone: this.data.phone || "",
        openTime: openTime,
        closeTime: closeTime,
        type: "S",
      };

      const response = await DeliveryApi.checkMoaPos(serviceItem);
      if (response.data) {
        if (this.data.information) {
          this.data.moaPosStoreId = response.data;
        } else {
          this.$set(this.data, "moaPosStoreId", response.data);
        }
        console.log("모아포스 :: " + this.data.moaPosStoreId);
        this.$message.success("ID 동기화가 성공적으로 완료되었습니다.");

        this.$forceUpdate();
      } else {
        throw new Error("모아포스 ID를 받지 못했습니다.");
      }
    } catch (error) {
      this.$message.error(`ID 동기화 중 오류가 발생했습니다: ${error.message}`);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
    }
  }

  get rules() {
    return {
      ...this.tabs.reduce(
        (result, { key }) => ({
          ...result,
          [`information.${key}.name`]: [
            {
              required: this.isLanguageUsed(key),
              message: i18n.tc("components.modal.delivery.msg"),
            },
          ],
          businessNumber: [
            {
              required: true,
              message: "사업자번호를 넣어주세요.",
            },
            {
              min: 10,
              message: "사업자번호는 10자리 숫자여야합니다.",
            },
          ],
        }),
        {},
      ),
    };
  }

  async save() {
    this.$refs.editServiceForm.validate(async valid => {
      if (valid) {
        try {
          if (!this.data.uploadedUrl[0]) {
            return this.$message.warning(
              i18n.tc("components.modal.item.uploadImgWarn"),
            );
          }

          if (!this.data.information.En.name) {
            this.$refs.languageTabForm.tabDefaultKeyChange();
            return this.$message.warning(
              i18n.tc("components.modal.delivery.engMsg"),
            );
          }

          this.loading = true;
          this.$emit("saveSubmit");
        } catch {
        } finally {
          this.loading = false;
        }
      }
    });
  }
}
</script>

<style lang="scss" scoped>
.image-expand {
  position: relative;
  height: 500px;
  width: 100%;

  &::after {
    padding-top: 100%;
    content: "";
  }

  > div {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    position: absolute;
    left: 0px;
    top: -5px;
    right: 0px;
    bottom: -5px;
  }
}
</style>
