<template>
  <a-modal width="1200px" :visible="visible" :closable="false">
    <div df fdr fic fjb slot="title">
      <div>
        {{
          levelCheck === "1"
            ? $t("components.modal.storeInfo")
            : $t("components.modal.updateStoreInfo")
        }}
      </div>
    </div>

    <a-form-model
      v-if="data"
      :key="data.index"
      ref="editServiceForm"
      :model="data"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <div df fdr>
        <a-space direction="vertical" flex style="padding: 10px 20px 10px 0px">
          <upload
            dir-name="icon"
            v-model="data"
            listType="picture-card"
            :selected="true"
            :disabled="levelCheck === '1' ? true : false"
          >
            <div class="image-expand">
              <div
                v-if="data.uploadedUrl[0]"
                :style="{
                  backgroundImage: `url('${data.uploadedUrl[0]}')`,
                }"
              />
              <div v-else df fdc fjc fic>
                <a-icon :type="'plus'" />
                <div>{{ $t("components.modal.upload") }}</div>
                <div>{{ $t("components.modal.uploadEx") }}</div>
              </div>
            </div>
          </upload>
        </a-space>

        <a-space direction="vertical" flex>
          <div df fdr fje>
            <small>{{ $t("components.modal.uploadCaution") }}</small>
          </div>
          <div class="card-container">
            <language-tab
              ref="languageTabForm"
              :value="data"
              :isLanguageUsed="isLanguageUsed"
              :tabs="tabs"
              :name="$t('components.modal.name')"
              :description="$t('components.modal.description')"
              :descriptionRows="1"
            >
            </language-tab>
          </div>

          <a-form-model-item
            v-if="rumyCheck > 0 ? true : false"
            prop="isRumy"
            :label="$t('components.modal.UseRumy')"
          >
            <a-switch v-model="data.isRumy" />
          </a-form-model-item>

          <a-form-model-item
            v-if="rumyCheck > 0 ? true : false"
            prop="departmentIds"
            :label="$t('components.modal.departRumy')"
          >
            <a-select
              v-if="rumyCheck > 0 ? true : false"
              :disabled="!data.isRumy"
              mode="multiple"
              :placeholder="$t('components.modal.checkDepartRumy')"
              v-model="data.departmentIds"
            >
              <a-select-option
                v-for="item in rumyDepartmentList"
                :key="item.objectId"
                :value="item.objectId"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item prop="businessNumber" label="사업자번호">
            <a-input v-model="data.businessNumber" disabled />
          </a-form-model-item>

          <a-form-model-item prop="phone" :label="$t('components.modal.tel')">
            <a-input v-model="data.phone" />
          </a-form-model-item>

          <a-form-model-item
            prop="isAlways"
            :label="$t('components.modal.saleTime')"
          >
            <a-radio-group button-style="solid" v-model="data.isAlways">
              <a-radio-button @click="isAlwaysChange" :value="true">{{
                $t("components.modal.always")
              }}</a-radio-button>
              <a-radio-button @click="isAlwaysChange" :value="false">{{
                $t("components.modal.individual")
              }}</a-radio-button>
            </a-radio-group>

            <div df fdr fjb style="margin-top: 6px; margin-bottom: 6px">
              <div df fdc fic>
                {{ $t("components.modal.openTime") }}
                <a-time-picker
                  format="HH:mm"
                  :disabled="data.isAlways"
                  v-model="data.openTime"
                >
                </a-time-picker>
              </div>
              <div df fjc fic>~</div>
              <div df fdc fic>
                {{ $t("components.modal.endTime") }}
                <a-time-picker
                  format="HH:mm"
                  :disabled="data.isAlways"
                  v-model="data.closeTime"
                >
                </a-time-picker>
              </div>
            </div>
          </a-form-model-item>

          <a-form-model-item :label="$t('components.modal.expectTime')">
            <a-input-number
              style="width: 120px"
              v-model="data.estdTime"
              :min="0"
              :max="10000"
              :step="5"
              :formatter="value => `${value} ${$t('components.modal.min')}`"
              :disabled="data.estdTime == 0 ? true : false"
            >
            </a-input-number>

            <a-switch
              style="margin-left: 10px"
              :checked="data.estdTime != 0"
              @change="toggleChange"
            />
          </a-form-model-item>

          <a-form-model-item prop="status" :label="$t('components.modal.use')">
            <a-radio-group button-style="solid" v-model="data.status">
              <a-radio-button :value="true">{{
                $t("components.modal.use")
              }}</a-radio-button>
              <a-radio-button :value="false">{{
                $t("components.modal.unuse")
              }}</a-radio-button>
            </a-radio-group>
          </a-form-model-item>
        </a-space>
      </div>
    </a-form-model>

    <div slot="footer">
      <a-button @click="closeModal">{{
        $t("components.modal.cancel")
      }}</a-button>
      <a-button
        v-if="levelCheck === '1' ? false : true"
        type="primary"
        :loading="loading"
        @click="save"
        >{{ $t("components.modal.save") }}</a-button
      >
    </div>
  </a-modal>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";

import LanguageTab from "@/components/LanguageTab";
import Upload from "@/components/Upload";
import Tabs from "@/utils/languageTab";

import ServiceApi from "@/api/admin/service";
import HotelApi from "@/api/admin/hotel";
import RumyApi from "@/api/shared/rumy";
import { i18n } from "@/i18n";

@Component({
  components: { Upload, LanguageTab },
})
export default class DeliveryItemEditModal extends Vue {
  levelCheck = localStorage.getItem("level");
  @Prop({ default: undefined }) data;
  @Prop({ default: false }) visible;

  labelCol = { span: 6 };
  wrapperCol = { span: 18 };

  tabs = Tabs;

  loading = false;

  rumyCheck = false;
  rumyDepartmentList = [];
  rumyInfo = {};

  closeModal() {
    this.data.index = null;

    this.$emit("close");
  }

  isLanguageUsed(key) {
    if (key === "Kr" || key === "En") {
      return true;
    }
  }

  isAlwaysChange() {
    this.data.openTime = "0000";
    this.data.closeTime = "0000";
  }

  get rules() {
    return {
      ...this.tabs.reduce(
        (result, { key }) => ({
          ...result,
          [`information.${key}.name`]: [
            {
              required: this.isLanguageUsed(key),
              message: i18n.tc("components.modal.storeNameMsg"),
            },
          ],
        }),
        {},
      ),
    };
  }

  mounted() {
    this.rumyCheckLoad();
  }

  toggleChange(e) {
    !e ? (this.data.estdTime = 0) : (this.data.estdTime = 60);
  }

  async save() {
    this.$refs.editServiceForm.validate(async valid => {
      if (valid) {
        try {
          if (!this.data.uploadedUrl[0]) {
            return this.$message.warning("이미지를 업로드해주세요.");
          }

          if (!this.data.information.En.name) {
            this.$refs.languageTabForm.tabDefaultKeyChange();
            return this.$message.warning(
              i18n.tc("components.modal.storeNameWarn"),
            );
          }

          this.loading = true;
          await this.$emit("saveSubmit");

          this.closeModal();
        } catch {
        } finally {
          this.loading = false;
        }
      }
    });
  }

  async rumyCheckLoad() {
    try {
      this.loading = true;
      this.rumyDepartmentList = [];

      const data = await ServiceApi.RumyCheck(this.$route.params.key);

      if (data.data === true) {
        const hotelInfo = await HotelApi.Detail(this.$route.params.key);

        if (hotelInfo.confHotel) {
          this.rumyInfo = {
            url: hotelInfo.confHotel.rumyUrl ? hotelInfo.confHotel.rumyUrl : "",
            id: hotelInfo.confHotel.rumyId ? hotelInfo.confHotel.rumyId : "",
            password: hotelInfo.confHotel.rumyPw
              ? hotelInfo.confHotel.rumyPw
              : "",
          };

          const rumyInfo = await RumyApi.List(this.rumyInfo);
          this.rumyDepartmentList = rumyInfo.data.teams;
        }
      }

      this.rumyCheck = this.rumyDepartmentList?.length > 0 ? true : false;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("components.modal.rumyWarn");

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.image-expand {
  position: relative;
  height: 500px;
  width: 100%;

  &::after {
    padding-top: 100%;
    content: "";
  }

  > div {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    position: absolute;
    left: 0px;
    top: -5px;
    right: 0px;
    bottom: -5px;
  }
}
</style>
