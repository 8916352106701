import { i18n } from "@/i18n";
export function OrderColumns() {
  return [
    {
      width: "80px",
      align: "right",
      dataIndex: "index",
      title: "주문ID",
    },
    {
      width: 110,
      align: "left",
      dataIndex: "registeredTime",
      title: "일자",
      scopedSlots: { customRender: "registeredTime" },
    },
    {
      width: 80,
      align: "left",
      title: "시간",
      scopedSlots: { customRender: "time" },
    },
    {
      width: "120px",
      dataIndex: "status",
      title: i18n.tc("js.order.status"),
      scopedSlots: { customRender: "status" },
    },
    {
      dataIndex: "hotelName",
      title: "호텔명",
    },
    {
      dataIndex: "roomNumber",
      title: i18n.tc("js.order.room"),
    },
    {
      dataIndex: "serviceName",
      title: i18n.tc("js.order.service"),
    },
    {
      align: "right",
      dataIndex: "payStatus",
      title: i18n.tc("js.order.payStatus"),
      scopedSlots: { customRender: "payStatus" },
    },
    {
      align: "right",
      dataIndex: "amount",
      title: i18n.tc("js.order.price"),
      customRender: value => Number(value).toLocaleString(),
    },
    {
      width: "120px",
      align: "center",
      dataIndex: "details",
      title: i18n.tc("js.order.orderDis"),
      scopedSlots: { customRender: "details" },
    },
  ];
}
